@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Telegraf";
  src: url("./assets/fonts/telegraf/telegraf-regular.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Telegraf";
  src: url("./assets/fonts/telegraf/telegraf-ultralight.otf") format("opentype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Telegraf";
  src: url("./assets/fonts/telegraf/telegraf-ultrabold.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
}

#root {
  min-height: 100vh;
}

.custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: rgba(155, 155, 155, 0.7) transparent;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: transparent;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(155, 155, 155, 0.7);
  border-radius: 20px;
  border: 3px solid transparent;
  background-clip: content-box;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: rgba(155, 155, 155, 0.8);
}

.noselect {
  --muted: 0 0% 96.1%;
  -webkit-user-select: none; /* Safari */
  --muted-foreground: 0 0% 45.1%;
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.bg-radial-gradient {
  background-image: radial-gradient(
    211.5% 113.1% at -66.4% 35.9%,
    #05100f 0%,
    /* Almost Black Green */ #0a1c11 25%,
    /* Very Dark Forest Green */ #16231e 50%,
    /* Dark Slate Green */ #1e392b 75%,
    /* Slightly Brighter Dark Green */ #131f1a 85%,
    /* Blackened Green */ #091015 100% /* Deep Black Green */
  );
}
ul {
  list-style-type: disc;
}

ol {
  list-style-type: decimal;
}

.MuiPaper-root {
  background-color: #ffffff;
}

.noselect {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

a {
  text-decoration: none;
  color: inherit; /* or any specific color */
}

body {
  background-color: #fafafa; /* bg-neutral-50 */
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
}

.bg-radial-gradient {
  background-image: radial-gradient(
    211.5% 113.1% at -66.4% 35.9%,
    #05100f 0%,
    /* Almost Black Green */ #0a1c11 25%,
    /* Very Dark Forest Green */ #16231e 50%,
    /* Dark Slate Green */ #1e392b 75%,
    /* Slightly Brighter Dark Green */ #131f1a 85%,
    /* Blackened Green */ #091015 100% /* Deep Black Green */
  );
}

.bg-gradient-shadow {
  background-image: linear-gradient(
    0deg,
    hsla(0, 0%, 0%, 0) 0%,
    hsla(0, 0%, 0%, 0.03) 11.872%,
    hsla(0, 0%, 0%, 0.1) 22.496%,
    hsla(0, 0%, 0%, 0.22) 32.184000000000005%,
    hsla(0, 0%, 0%, 0.35) 41.248000000000005%,
    hsla(0, 0%, 0%, 0.5) 50%,
    hsla(0, 0%, 0%, 0.65) 58.752%,
    hsla(0, 0%, 0%, 0.78) 67.816%,
    hsla(0, 0%, 0%, 0.9) 77.50399999999999%,
    hsla(0, 0%, 0%, 0.97) 88.128%,
    hsl(0, 0%, 0%) 100%
  );
}

.card-text-clamp {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%; /* Ensure this matches the specified width of your items */
}

.safe-nav-margin {
  margin-top: calc(3.75rem + env(safe-area-inset-top, 0.25rem) - env(safe-area-inset-top, 0));
  margin-bottom: calc(2.5rem + env(safe-area-inset-bottom, 1.5rem) - env(safe-area-inset-bottom, 0));
}

@media (min-width: 768px) {
  .safe-nav-margin {
    margin-top: env(safe-area-inset-top, 0);
    margin-bottom: env(safe-area-inset-bottom, 0);
  }
}

.safe-nav-padding {
  padding-top: calc(3.75rem + env(safe-area-inset-top, 0.25rem) - env(safe-area-inset-top, 0));
  padding-bottom: calc(2.5rem + env(safe-area-inset-bottom, 1.5rem) - env(safe-area-inset-bottom, 0));
}

@media (min-width: 768px) {
  .safe-nav-padding {
    padding-top: env(safe-area-inset-top, 0);
    padding-bottom: env(safe-area-inset-bottom, 0);
  }
}

.auto-fill-minmax-200 {
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
}

.auto-fill-minmax-250 {
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
}

.auto-fill-minmax-300 {
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
}

.auto-fill-minmax-350 {
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
}

.auto-fill-minmax-400 {
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
}

.auto-fill-minmax-450 {
  grid-template-columns: repeat(auto-fill, minmax(450px, 1fr));
}

.auto-fill-minmax-500 {
  grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
}

@media (min-width: 768px) {
  .sm\:auto-fill-minmax-200 {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }

  .sm\:auto-fill-minmax-250 {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }

  .sm\:auto-fill-minmax-300 {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }

  .sm\:auto-fill-minmax-350 {
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  }

  .sm\:auto-fill-minmax-400 {
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  }

  .sm\:auto-fill-minmax-450 {
    grid-template-columns: repeat(auto-fill, minmax(450px, 1fr));
  }

  .sm\:auto-fill-minmax-500 {
    grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
  }

  .md\:auto-fill-minmax-200 {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }

  .md\:auto-fill-minmax-250 {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
  .md\:auto-fill-minmax-300 {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }
  .md\:auto-fill-minmax-350 {
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  }

  .md\:auto-fill-minmax-400 {
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  }

  .md\:auto-fill-minmax-450 {
    grid-template-columns: repeat(auto-fill, minmax(450px, 1fr));
  }

  .md\:auto-fill-minmax-500 {
    grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
  }

  .lg\:auto-fill-minmax-200 {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }

  .lg\:auto-fill-minmax-250 {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }

  .lg\:auto-fill-minmax-300 {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }

  .lg\:auto-fill-minmax-350 {
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  }

  .lg\:auto-fill-minmax-400 {
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  }

  .lg\:auto-fill-minmax-450 {
    grid-template-columns: repeat(auto-fill, minmax(450px, 1fr));
  }

  .lg\:auto-fill-minmax-500 {
    grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
  }

  .xl\:auto-fill-minmax-200 {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }

  .xl\:auto-fill-minmax-250 {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }

  .xl\:auto-fill-minmax-300 {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }

  .xl\:auto-fill-minmax-350 {
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  }

  .xl\:auto-fill-minmax-400 {
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  }

  .xl\:auto-fill-minmax-450 {
    grid-template-columns: repeat(auto-fill, minmax(450px, 1fr));
  }

  .xl\:auto-fill-minmax-500 {
    grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
  }
}

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 0 0% 3.9%;

    --card: 0 0% 100%;
    --card-foreground: 0 0% 3.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 0 0% 3.9%;

    --primary: 117, 23%, 44%;
    --primary-foreground: 0 0% 98%;

    --secondary: 0 0% 96.1%;
    --secondary-foreground: 0 0% 9%;

    --muted: 0 0% 96.1%;
    --muted-foreground: 0 0% 45.1%;

    --accent: 0 0% 96.1%;
    --accent-foreground: 0 0% 9%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;

    --border: 0 0% 89.8%;
    --input: 0 0% 89.8%;
    --ring: 0 0% 3.9%;

    --radius: 0.5rem;

    --chart-1: 117, 23%, 44%;
    --chart-2: 117, 24%, 47%;
    --chart-3: 117, 25%, 51%;
    --chart-4: 117, 29%, 55%;
    --chart-5: 117, 35%, 58%;

    --separator: #362e2d26;
  }

  .dark {
    --background: 0 0% 3.9%;
    --foreground: 0 0% 98%;

    --card: 0 0% 3.9%;
    --card-foreground: 0 0% 98%;

    --popover: 0 0% 3.9%;
    --popover-foreground: 0 0% 98%;

    --primary: 0 0% 98%;
    --primary-foreground: 0 0% 9%;

    --secondary: 0 0% 14.9%;
    --secondary-foreground: 0 0% 98%;

    --muted: 0 0% 14.9%;
    --muted-foreground: 0 0% 63.9%;

    --accent: 0 0% 14.9%;
    --accent-foreground: 0 0% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 0% 98%;

    --border: 0 0% 14.9%;
    --input: 0 0% 14.9%;
    --ring: 0 0% 83.1%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}
