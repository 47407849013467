.noselect {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

a {
  text-decoration: none;
  color: inherit; /* or any specific color */
}

body {
  background-color: #ffffff;
}
